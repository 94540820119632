@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.art-btn-yellow-content {
  background: linear-gradient(180deg, #f9c744, #df8c00);
  box-shadow: 0 4px 4px rgb(72 77 9 / 16%);
  color: white;
}

.art-btn-red-content {
  background: linear-gradient(180deg, #d55050, #a43535);
  box-shadow: 0 4px 4px rgb(72 77 9 / 16%);
  color: white;
}
